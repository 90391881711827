<template>
  <b-card title="Download software">
    <b-list-group>
      <b-list-group-item href="https://pim.arredo3.it/portale-vettori/ubsign_arredo3_prod_v4.apk" download class="d-flex">
        <span class="mr-2">
          <feather-icon
            icon="DownloadIcon"
            size="18"
          />
        </span>
        <span>APP UBSIGN</span>
      </b-list-group-item>

      <b-list-group-item href="https://pim.arredo3.it/portale-vettori/installer-app-firma-digitale.apk" download class="d-flex">
        <span class="mr-2">
          <feather-icon
            icon="DownloadIcon"
            size="18"
          />
        </span>
        <span>APP DI FIRMA GRAFOCERTA</span>
      </b-list-group-item>

      <b-list-group-item href="https://pim.arredo3.it/portale-vettori/licenza-firma-digitale.afgclic" download class="d-flex">
        <span class="mr-2">
          <feather-icon
            icon="DownloadIcon"
            size="18"
          />
        </span>
        <span>LICENZA APP DI FIRMA</span>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<script>
import { BCard, BCardText, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
  },
}
</script>

<style>

</style>
